<template>
    <div>
    <el-card class="box-card card-contanier">
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <div v-show="nohigh" style="float: left">
            <el-form-item>
              <el-input
               v-model="searchForm.username"
                size="medium"
                placeholder="操作人"
                clearable
                @keyup.enter.native="getList">
              </el-input>
            </el-form-item>
            <!-- <el-form-item>
              <el-input
                v-model="searchForm.solId"
                size="medium"
                placeholder="操作记录id"
                clearable
                @keyup.enter.native="getListSyPrivateVip">
              </el-input>
            </el-form-item> -->

            <el-form-item>
              <el-button @click="search" size="medium">搜索</el-button>
            </el-form-item>
          </div>

          <div style="float: right; margin-right: 20px">
            <el-form-item class="high_serch">
              <el-button
                @click="showHighSerch"
                :icon="nohigh ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                type="text"
                size="medium">高级搜索
              </el-button>
            </el-form-item>
          </div>
          <div v-show="!nohigh" style="float: left; width: 90%">
            <el-form-item label="操作人:">
              <el-input
                v-model="searchForm.username"
                size="medium"
                placeholder="操作人"
                clearable>
              </el-input>
            </el-form-item>

            <el-form-item label="操作模块:">
              <el-input
                v-model="searchForm.solModule"
                size="medium"
                placeholder="操作模块"
                clearable>
              </el-input>
            </el-form-item>

            <el-form-item label="请求路径:">
              <el-input
                v-model="searchForm.solPath"
                size="medium"
                placeholder="请求路径"
                clearable>
              </el-input>
            </el-form-item>

            <el-form-item label="请求方式:">
              <el-input
                v-model="searchForm.solMethod"
                size="medium"
                placeholder="请求方式"
                clearable>
              </el-input>
            </el-form-item>

            <el-form-item label="操作类型:">
              <el-select v-model="searchForm.solType" placeholder="请选择操作类型">
                <el-option value="" label="全部">全部</el-option>
                <el-option
                  v-for="item in pvipIsdelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="操作时间:">
              <el-date-picker
                v-model="searchForm.solCreateDm"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions" >
              </el-date-picker>
            </el-form-item>

            <!-- <el-form-item label="修改时间:">
              <el-date-picker
                v-model="searchForm.solUpdateDm"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions" >
              </el-date-picker>
            </el-form-item> -->


            <el-form-item>
              <el-button @click="search" icon="el-icon-search" size="medium">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="refresh" icon="el-icon-refresh" size="medium">重置</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-card>

    <el-table
      :data="tableData"
      border
      style="width: 100%">
      <!-- <el-table-column
        prop="solId"
        label="操作记录id"
        width="180"
        fixed
        align="center">
      </el-table-column> -->

      <el-table-column
        prop="username"
        label="操作人"
        width="200"
        show-overflow-tooltip
        align="center">
      </el-table-column>

      <el-table-column
        prop="solModule"
        label="操作模块"
        width="200"
        show-overflow-tooltip
        align="center">
      </el-table-column>

      <el-table-column
        prop="solMethod"
        label="请求方式"
        width="200"
        show-overflow-tooltip
        align="center">
        <!-- <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.solMethod === '0'" type="success" >get</el-tag>
          <el-tag size="small" v-else-if="scope.row.solMethod === '1'" type="success" >post</el-tag>
          <el-tag size="small" v-else-if="scope.row.solMethod === '2'" type="success">delete</el-tag>
        </template> -->
      </el-table-column>

      <el-table-column
        prop="solType"
        label="操作类型"
        width="200"
        show-overflow-tooltip
        align="center">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.solType==0"  type="success" >保存</el-tag>
          <el-tag size="small" v-else-if="scope.row.solType==1"  type="success">更新</el-tag>
          <el-tag size="small" v-else-if="scope.row.solType==2" type="success">删除</el-tag>
          <el-tag size="small" v-else-if="scope.row.solType==3"  type="success">搜索</el-tag>
        </template>
      </el-table-column>

      <el-table-column 
        prop="solPath" 
        label="请求路径" 
        width="200" 
        align="center">
      </el-table-column>

      <!-- <el-table-column 
        prop="solDura" 
        label="请求耗时" 
        width="180" 
        align="center">
      </el-table-column>

      <el-table-column 
        prop="solReqData" 
        label="请求数据"
        width="180" 
        align="center">
      </el-table-column> -->

      <el-table-column 
        prop="solResData" 
        label="返回数据" 
        width="200" 
        show-overflow-tooltip
        align="center">
      </el-table-column>

      <el-table-column 
        prop="solCreateDm" 
        label="操作时间" 
        width="200" 
        align="center">
        <template slot-scope="scope">
          {{ scope.row.solCreateDm.replace('T',' ')}}
        </template>
      </el-table-column>

      <!-- <el-table-column 
        prop="solUpdateDm" 
        label="修改时间" 
        width="180" 
        align="center">
        <template v-if="scope.row.solUpdateDm !== null" slot-scope="scope">
          {{ scope.row.solUpdateDm.replace('T',' ')}}
        </template>
      </el-table-column> -->

      <el-table-column 
        prop="solIp" 
        label="请求ip" 
        width="200" 
        align="center">
      </el-table-column>

      <el-table-column 
        prop="solException" 
        label="异常信息" 
        width="200" 
        align="center">
      </el-table-column>

      <el-table-column 
        prop="state" 
        label="调用结果" 
        width="200" 
        align="center">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.solState === 0" type="success">正常</el-tag>
          <el-tag size="small" v-else-if="scope.row.solStat === 1" type="danger">异常</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        style="margin-top: 12px;display: flex;justify-content: flex-end;"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
   
   </div>
</template>

<script>
import api from "../../api/user";

export default {
  name: "Intces",
  data() {
    return {
      total: 0,
      size: 10,
      current: 1,

      dialogVisible: false,
      nohigh: true,
      editForm: {},
      tableData: [],
      searchForm: {},
      pvipIsdelOptions: [
        {
          value: 0,
          label: "保存",
        },
        {
          value: 1,
          label: "更新",
        },
        {
          value: 2,
          label: "删除",
        },
        {
          value: 3,
          label: "搜索",
        },
      ],
      solCreateDm:"",
      solUpdateDm:"",
      editFormRules: {
        username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        solModule: [{ required: true, message: "请输入操作模块", trigger: "blur" }],
        solMethod: [{ required: true, message: "请选输入请求方式", trigger: "blur" }],
        solType:[{ required: true, message: "请选输入操作类型", trigger: "blur" }],
        solPath:[{ required: true, message: "请选输入请求路径", trigger: "blur" }],
        solResData:[{ required: true, message: "请选输入返回数据", trigger: "blur" }],
        solCreateDm:[{ required: true, message: "请选输入创建时间", trigger: "blur" }],
        solUpdateDm:[{ required: true, message: "请选输入修改时间", trigger: "blur" }],
        solIp:[{ required: true, message: "请选输入请求IP", trigger: "blur" }],
        solException:[{ required: true, message: "请选输入异常信息", trigger: "blur" }],
        state:[{ required: true, message: "请选输入调用结果", trigger: "blur" }],

    },
    pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
  }
},
  created() {
    this.getRZList();
    api.querySysById().then((res)=>{
        this.data = res.data.data.result;
      });
  },
  methods: {
    handleSizeChange(val) {
      this.size = val;
      this.getRZList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getRZList();
    },
    getRZList() {
      let startDate = '';
      let endDate = '';
      if(this.searchForm.solCreateDm !== undefined){
         startDate = this.searchForm.solCreateDm[0];
         endDate = this.searchForm.solCreateDm[1];
      }
      api.SysList(this.searchForm.username,
                  this.searchForm.solModule,
                  this.searchForm.solPath,
                  this.searchForm.solType,
                  startDate,
                  endDate,
                  this.searchForm.solMethod,
                  this.size,
                  this.current
                  ).then((res) => {
        // this.tableData = res.data.data.result;
          this.tableData = res.data.data.result.records;
          this.size = res.data.data.result.size;
          this.total = res.data.data.result.total;
      })
      },  
    search(){
      this.current = 1;
      this.getRZList();
    },
    showHighSerch() {
      this.nohigh = !this.nohigh;
    },
    // //保存
    //   submitForm(formName) {
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       let status = this.editForm.solId ? "update" : "save";
    //       api.saveProv(status, this.editForm).then((res) => {
    //         this.$notify({
    //           title: "prefect",
    //           message: "操作成功",
    //           position: "bottom-right",
    //           type: "success",
    //           onClose: () => {
    //             this.getRZList();
    //           },
    //         });
    //         this.dialogVisible = false;
    //         this.refresh();
    //       });
    //     } else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    //   },
    //编辑
    editHandler(solId) {
          this.solId = solId
          api.querySysById(solId).then(res => {
          this.editForm = res.data.data.result;
        });
        this.dialogVisible = true;
      },
     refresh() {
      this.searchForm = {
        username: "",
        solModule:"",
        solPath:"",
        solCreateDm:"",
        solUpdateDm:"",
     };
     this.current = 1;
      this.getRZList();
    },
    delHandle(solId) {
        api.deletes(solId).then((res) => {
          this.$notify({
            title: "prefect",
            message: res.data.message,
            position: "bottom-right",
            type: res.data.code === 200 ? "success" : "error",
          });
          this.getRZList();
        });
      },
    resetForm(formName) {
        this.$refs[formName].resetFields();
        this.dialogVisible = false;
        this.editForm = {};
      },
      handleClose() {
        this.resetForm("editForm");
        this.dialogVisible = false; 
        this.editForm = {};
      },
    
  }
};
</script>

<style scoped>
.card-contanier {
  margin-bottom: 5px;
}
</style>